
export default {
  SET_CONTROL(state, value) {
    state.control = value;
  },
  UNSET_CONTROL(state) {
    state.control = {};
  },
  ADD_REQUEST(state, promise) {
    state.requests.push(promise);
  },
  REMOVE_REQUEST(state, promise = null) {
    if (promise) {
      const idx = state.requests.indexOf(promise);
      state.requests.splice(idx, 1);
    } else {
      state.requests = [];
    }
  },
  REPLACE_GOODS_ITEM_IN_DECLARATION(state, items) {
    state.selectedWares = items;
  },
  SET_SELECTED(state, { wares = [], ...rest }) {
    wares.length
      ? state.selectedWares.splice(0, state.selectedWares.length, ...wares)
      : state.selectedWares.splice(0, state.selectedWares.length);

    state.selected = rest;
    state.selectedId = rest.id;
  },

  UPDATE_SELECTED_FIELDS(state, { name, value }) {
    state.selected[name] = value;
  },

  // ОБЪЕДИНИТЬ МАССИВЫ presented_documents (для ЗВТ, РС, ДТ, ТД, КДТ)
  JOIN_PRESENTED_DOCUMENT(state, { items, shipmentIndex} ) {
    if (state.selected.presented_documents) {
      state.selected.presented_documents = state.selected.presented_documents?.concat(items);
    } else
      state.selected.ware_shipments[shipmentIndex].presented_documents = state.selected?.ware_shipments[shipmentIndex]?.presented_documents.concat(items);
  },

  //  ОБНОВИТЬ ДОКУМЕНТ
  UPDATE_DOCUMENT_IN_DECLARATION(state, items) {
    if (items.length === state.selected.presented_documents.length) {
      state.selected.presented_documents.splice(
        0,
        state.selected.presented_documents.length,
        ...items
      );
    } else {
      items.forEach((item) => {
        const { id } = item;
        const current = state.selected.presented_documents.find(
          (i) => i.id === id
        );
        const index = state.selected.presented_documents.indexOf(current);
        console.log("STORE UPDATE DOC idx >>", index);
        state.selected.presented_documents.splice(index, 1, item);
      });
    }
  },

  UPDATE_PRECEDING_DOCUMENT_IN_DECLARATION(state, items) {
    if (items.length === state.selected.preceding_documents.length) {
      state.selected.preceding_documents.splice(
        0,
        state.selected.preceding_documents.length,
        ...items
      );
    } else {
      items.forEach((item) => {
        const { id } = item;
        const current = state.selected.preceding_documents.find(
          (i) => i.id === id
        );
        const index = state.selected.preceding_documents.indexOf(current);
        console.log("STORE UPDATE DOC idx >>", index);
        state.selected.preceding_documents.splice(index, 1, item);
      });
    }
  },

  // ДОБАВИТЬ ДОКУМЕНТЫ
  ADD_DOCUMENT(state, { item, nextItemPosition }) {
    state.selected.presented_documents.splice(nextItemPosition, 0, item);
  },

  ADD_PRECEDING_DOCUMENT(state, { item, nextItemPosition }) {
    state.selected.preceding_documents.splice(nextItemPosition, 0, item);
  },

  // когда возвращается несколько блоков
  REPLACE_BLOCK_IN_DECLARATION(state, blocks) {
    Object.keys(blocks).forEach((block) => {
      if (block in state.selected) {
        state.selected[block] = blocks[block];
      }
      if (block === "wares") {
        state.selectedWares = blocks[block];
      }
    });
  },

  UPDATE_GOODS_ITEM_IN_DECLARATION(state, items) {
    items.forEach((item) => {
      const { id } = item;
      const current = state.selectedWares.find((i) => i.id === id);
      const index = state.selectedWares.indexOf(current);
      console.log("STORE UPDATE GOOD idx >>", index);
      state.selectedWares.splice(index, 1, item);
    });
  },

  ADD_GOODS_ITEM(state, { item, nextItemPosition }) {
    state.selectedWares.splice(nextItemPosition, 0, item);
  },
  RESET_GOODS(state, blocks) {
    state.selectedWares = blocks;
  },
  SET_NEW_WARRANTY(state, item) {
    state.selected.guarantees.push(item);
  },
  DELETE_WARRANTY(state, payload) {
    const current = state.selected.guarantees.find((i) => i.id === payload);
    const index = state.selected.guarantees.indexOf(current);
    state.selected.guarantees.splice(index, 1);
  },
  UPDATE_WARRANTY_IN_DECLARATION(state, items) {
    if (items.length === state.selected.guarantees.length) {
      state.selected.guarantees.splice(
        0,
        state.selected.guarantees.length,
        ...items
      );
    } else {
      items.forEach((item) => {
        const { id } = item;
        const current = state.selected.guarantees.find((i) => i.id === id);
        const index = state.selected.guarantees.indexOf(current);
        state.selected.guarantees.splice(index, 1, item);
      });
    }
  },

};
