import axios from "axios";
import moment from "moment";
import {
  filterDefault,
  getCurrentTypedParams,
  getDefaultSearchPlaceForType,
  getJournalUrl,
  getSelectedType,
  getUrl,
  journalDocumentTypes,
} from "@/helpers/journal";

const DEFAULT_DOCUMENT_TYPE = "sd";
const DEFAULT_SELECT_TYPE = "user";

export default {
  namespaced: true,
  state: {
    items: [],
    totalCount: 0,
    documentTypes: journalDocumentTypes(),
    selectType: DEFAULT_SELECT_TYPE,
    documentType: DEFAULT_DOCUMENT_TYPE,
    pagination: {
      page: 1,
      size: 15,
    },
    search: {
      searchBy: getDefaultSearchPlaceForType(DEFAULT_DOCUMENT_TYPE),
      searchValue: null,
    },
    typeDependentParams: [], // Хранит свойства которые зависят от типа выбранного документа
    filter: filterDefault(),
  },
  mutations: {
    SET_SELECT_TYPE(state, type) {
      state.selectType =
        type === DEFAULT_SELECT_TYPE ? DEFAULT_SELECT_TYPE : "division";
    },
    SET_AVAILABLE_DOCUMENT_TYPES(state) {
      // ТК данные модуля хранятся в LocalStorage,
      // то при добавлении нового типа документа через код он перезатрется имеющимися,
      // поэтому не будет отображаться в журнале.
      // Данная функция вызовет перезапись свойства
      state.documentTypes = journalDocumentTypes();
    },
    SET_DOCUMENT_TYPE(state, type) {
      const available = state.documentTypes.map((i) => i.value);
      if (available.includes(type)) {
        state.documentType = type;
      } else {
        state.documentType = DEFAULT_DOCUMENT_TYPE;
      }
    },
    SET_TYPE_DEPENDENT_PARAM(state, item) {
      const { type, name } = item;
      const index = state.typeDependentParams.findIndex(
        (i) => i.type === type && i.name === name
      );
      index !== -1
        ? state.typeDependentParams.splice(index, 1, item)
        : state.typeDependentParams.push(item);
    },
    SET_ITEMS(state, data) {
      state.totalCount = data.totalCount;
      state.items = [...data.items];
    },
    SET_PAGINATION(state, params) {
      state.pagination = {
        ...state.pagination,
        ...params,
      };
    },
    SET_FILTER(state, filter) {
      state.filter = {
        ...filter,
      };
    },
    SET_SEARCH(state, { searchBy = null, searchValue = null } = {}) {
      state.search.searchBy = searchBy;
      state.search.searchValue = searchValue;
    },
    UNSET_FILTER(state) {
      state.filter = filterDefault();
    },
  },
  actions: {
    base({ state }) {
      const { documentType } = state;
      const result = documentType.toString().toUpperCase();
      if (result) return result;
      throw new Error("Ошибка определения модуля");
    },
    fetchItems({ commit, state, rootState }) {
      const userId = rootState?.auth?.user?.user?.id;
      const mainUrl = getUrl(state);
      const journalUrl = getJournalUrl(state);

      let url = `${mainUrl}${journalUrl}?userId=${userId}&size=${state.pagination.size}&page=${state.pagination.page}`;

      Object.keys(state.filter).forEach((param) => {
        if (["declarationDateFrom", "declarationDateTo"].includes(param)) {
          state.filter[param]
            ? (url =
                url +
                `&${param}=${moment(state.filter[param]).format("DD.MM.YYYY")}`)
            : false;
        } else {
          state.filter[param]
            ? (url = url + `&${param}=${state.filter[param]}`)
            : false;
        }
      });

      if (state.search.searchBy && state.search.searchValue) {
        url = url + `&${state.search.searchBy}=${state.search.searchValue}`;
      }
      url = url + getSelectedType(state);
      url = url + getCurrentTypedParams(state);

      return axios
        .get(url)
        .then((res) => {
          commit("SET_ITEMS", res.data);
        })
        .catch((error) => {
          commit("SET_ITEMS", { totalCount: 0, items: [] });
          throw error;
        });
    },
    getEcdCstmList({ rootState, state }, id) {
      const userId = rootState?.auth?.user?.user?.id;
      return axios.get(
        `${getUrl(
          state
        )}declaration/getEcdCstmList?requestId=${id}&userId=${userId}`
      );
    },
    getEcdCstmDetails({ rootState, state }, { ln_id, ln_type, f_Id }) {
      const userId = rootState?.auth?.user?.user?.id;
      let baseUrl = `declaration/getEcdCstmDetails?userId=${userId}`;
      if (ln_id && ln_type) {
        baseUrl += `&linkId=${ln_id}&lnType=${ln_type}`;
      }
      if (f_Id) {
        baseUrl += `&blobId=${f_Id}`;
      }
      return axios.get(`${getUrl(state)}${baseUrl}`);
    },
    getStatusList({ rootState, state }, id) {
      const userId = rootState?.auth?.user?.user?.id;
      return axios.get(
        `${getUrl(
          state
        )}declaration/getCstmList?requestId=${id}&userId=${userId}`
      );
    },
    getStatusMessage({ rootState, state }, { ln_id, ln_type, f_Id }) {
      const userId = rootState?.auth?.user?.user?.id;
      let baseUrl = `declaration/getCstmDetails?userId=${userId}`;
      if (ln_id && ln_type >= 0) {
        baseUrl += `&linkId=${ln_id}&lnType=${ln_type}`;
      }
      if (f_Id) {
        baseUrl += `&blobId=${f_Id}`;
      }
      return axios.get(`${getUrl(state)}${baseUrl}`);
    },
    getStatusMessageFile({ rootState, state }, { f_Id, ln_id, ln_type }) {
      const userId = rootState?.auth?.user?.user?.id;
      let baseUrl = `declaration/getCstmDetailsFile?userId=${userId}`;
      if (f_Id) baseUrl += `&blobId=${f_Id}`;
      if (ln_id && ln_type >= 0)
        baseUrl += `&linkId=${ln_id}&lnType=${ln_type}`;
      return axios.get(`${getUrl(state)}${baseUrl}`);
    },
    getRawStatusMessage({ rootState, state }, id) {
      const userId = rootState?.auth?.user?.user?.id;
      return axios.get(
        `${getUrl(state)}declaration/getCstm?blobId=${id}&userId=${userId}`
      );
    },
  },
  getters: {
    getItems: (s) => s.items,
    pagination: (s) => s.pagination,
    totalCount: (s) => s.totalCount,
    getFilter: (s) => s.filter,
    getDocumentTypes: (s) => s.documentTypes,
    getDocumentType: (s) => s.documentType,
    getTypeDependentParams: (s) => s.typeDependentParams,
    getSelectType: (s) => s.selectType,
    getSearch: (s) => s.search,
  },
};
