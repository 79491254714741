import { CATALOGS, EPI, TNVED } from "@/http-routes";
import documentMutations from "./utils/document-mutations";
import documentActionsFactory from "./utils/document-actions";
import axios from "axios";
import shipmentMutations from "@/store/utils/shipment-mutations";
import shipmentActionsFactory from "@/store/utils/shipment-actions";

const documentActions = documentActionsFactory(EPI);
const shipmentActions = shipmentActionsFactory(EPI);
export default {
  namespaced: true,

  state: {
    selected: {},
    selectedWares: [],
    editableWare: { id: null, index: null },
    requests: [],
    aeos: [],
    nsiTransportMarks: [],
    shipmentIndex: null, // index
    control: {},
  },
  mutations: {
    ...documentMutations,
    ...shipmentMutations,
    SET_EDITABLE_WARE(state, id) {
      state.editableWare = id;
    },
    // ЗАМЕНИТЬ БЛОКИ TRANSPORT AND RELOADS
    // для обновления № п/п
    SET_TRANSPORT_AND_RELOADS(state, payload) {
      state.selected.transports = payload.transports;
      state.selected.reloads = payload.reloads;
    },
    // ДОБАВИТЬ ТРАНСПОРТ
    SET_NEW_TRANSPORT(state, item) {
      state.selected.transports.push(item);
    },
    // УДАЛИТЬ ТРАНСПОРТ
    DELETE_TRANSPORT(state, payload) {
      const current = state.selected.transports.find((i) => i.id === payload);
      const index = state.selected.transports.indexOf(current);
      state.selected.transports.splice(index, 1);
    },
    // ОБНОВИТЬ ТРАНСПОРТ
    UPDATE_TRANSPORT_IN_DECLARATION(state, items) {
      if (items.length === state.selected.transports.length) {
        state.selected.transports.splice(
          0,
          state.selected.transports.length,
          ...items
        );
      } else {
        items.forEach((item) => {
          const { id } = item;
          const current = state.selected.transports.find((i) => i.id === id);
          const index = state.selected.transports.indexOf(current);
          state.selected.transports.splice(index, 1, item);
        });
      }
    },
    SET_NSI_AEOS(state, aeos) {
      state.aeos = aeos;
    },
    SET_NSI_TRANSPORT_MARKS(state, nsiTransportMarks) {
      state.nsiTransportMarks = nsiTransportMarks;
    },
    // ПЕРЕГРУЗКИ
    CREATE_RELOAD(state, payload) {
      state.selected.reloads.push(payload);
    },
    UPDATE_RELOADS(state, payload) {
      state.selected.reloads = payload;
    },
    UPDATE_RELOAD(state, payload) {
      const current = state.selected.reloads.find((i) => i.id === payload.id);
      const index = state.selected.reloads.indexOf(current);
      state.selected.reloads.splice(index, 1, payload);
    },
    // ПЕРЕВОЗЧИКИ
    UPDATE_CARRIERS(state, payload) {
      state.selected.carriers = payload;
    },
    UPDATE_CARRIER(state, payload) {
      const index = state.selected.carriers.findIndex((i) => i.id === payload.id);
      state.selected.carriers.splice(index, 1, payload);
    },
    // ДОБАВИТЬ ТРАНСПОРТ К ПЕРЕГРУЗКЕ
    CREATE_RELOAD_TRANSPORT(state, payload) {
      const current = state.selected.reloads.find(
        (i) => i.id === payload.reload_id
      );
      current.transports.push(payload);
    },
    // УДАЛИТЬ ТРАНСПОРТ ИЗ ПЕРЕГРУЗКИ
    DELETE_RELOAD_TRANSPORT(state, payload) {
      const current = state.selected.reloads.find((i) => i.id === payload.id);
      const deleted_transport = current.transports.find(
        (i) => i.id === payload.transport_id
      );
      const index = current.transports.indexOf(deleted_transport);
      current.transports.splice(index, 1);
    },
  },
  actions: {
    ...documentActions,
    ...shipmentActions,
    // ПОЛУЧИТЬ МАССИВЫ ТРАНСОРТА В
    // 18 Транспортные средства и 55 Перегрузки
    getTransportsAndReloads({ commit, state }, ) {
      return axios
        .post(`${EPI}transport/getByTransportsWitReloadsByDeclarationId?declarationId=${state.selected.id}`)
        .then((res) => {
          console.log( "res.data", res.data)
          commit("SET_TRANSPORT_AND_RELOADS", res.data);

        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    fetchNsiAeos({ commit }) {
      return axios.get(`${CATALOGS}nsiAeos`).then((res) => {
        commit("SET_NSI_AEOS", res.data.list);
        return res;
      });
    },
    // ОБНОВИТЬ ТРАНСПОРТ
    updateTransport({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${EPI}transport/updateAll`, data)
        .then(() => {
          commit("UPDATE_TRANSPORT_IN_DECLARATION", data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ДОБАВИТЬ ТРАНСПОРТ
    createTransport({ commit, dispatch, state }) {
      return axios
        .post(`${EPI}transport/create?declarationId=${state.selected.id}`)
        .then((res) => {
          commit("SET_NEW_TRANSPORT", res.data);
          dispatch('getTransportsAndReloads');
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ТРАНСПОРТ
    deleteTransport({ dispatch, state }, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(
          `${EPI}transport/deleteFromDeclaration?declarationId=${state.selected.id}`,
          data
        )
        .then((res) => {
          // commit("DELETE_TRANSPORT", payload);
          dispatch('getTransportsAndReloads');
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    fetchNsiTransportMarks({ commit }) {
      return axios.get(`${CATALOGS}nsiTransportMarks`).then((res) => {
        const result =
          res.data?.list.map((i) => {
            return {
              ...i,
              text: [i.code, i.name].join("-"),
              search: [i.code, i.name].join("-").toLowerCase(),
            };
          }) || [];
        commit("SET_NSI_TRANSPORT_MARKS", result);
      });
    },
    // ДОБАВИТЬ ПЕРЕГРУЗКУ
    createReload({ dispatch, state }) {
      return axios
        .post(`${EPI}reload/create?declarationId=${state.selected.id}`)
        .then((res) => {
          // commit("CREATE_RELOAD", res.data);
          dispatch('getTransportsAndReloads');
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ПЕРЕГРУЗКУ
    deleteReload({ dispatch, state }, payload) {
      // commit("UPDATE_RELOADS");
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(
          `${EPI}reload/deleteByIds?declarationId=${state.selected.id}`,
          data
        )
        .then((res) => {
          // commit("UPDATE_RELOADS", res.data);
          dispatch('getTransportsAndReloads');
          return res;
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ОБНОВИТЬ ПЕРЕГРУЗКУ
    updateReload({ commit }, { payload }) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios.post(`${EPI}reload/updateAll`, data).then((res) => {
        commit("UPDATE_RELOAD", payload);
        return res;
      });
    },
    // ДОБАВИТЬ ТРАНСПОРТ К ПЕРЕГРУЗКЕ
    createReloadTransport({ dispatch, state }, id) {
      return axios
        .post(`${EPI}transport/create?declarationId=${state.selected.id}&reloadId=${id}`)
        .then(() => {
          dispatch('getTransportsAndReloads');
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ТРАНСПОРТ ИЗ ПЕРЕГРУЗКИ
    deleteReloadTransport({ dispatch }, payload) {
      const data = Array.isArray(payload.transport_id)
        ? payload.transport_id
        : [payload.transport_id];
      return axios
        .post(`${EPI}transport/deleteFromReload?reloadId=${payload.id}`, data)
        .then(() => {
          dispatch('getTransportsAndReloads');
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ДОБАВИТЬ ПЕРЕВОЗЧИКА
    createCarrier({ commit, state }, sortIndex) {
      axios
        .post(`${EPI}carrier/create?declarationId=${state.selected.id}&sortIndex=${sortIndex}`)
        .then((res) => {
          state.selected.carriers.push(res.data);
          commit("UPDATE_CARRIERS", state.selected.carriers);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // УДАЛИТЬ ПЕРЕВОЗЧИКА
    deleteCarrier({ commit, state }, id) {
      axios
        .post(`${EPI}carrier/deleteByIds?declarationId=${state.selected.id}`, [id])
        .then((res) => {
          commit("UPDATE_CARRIERS", res.data);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },
    // ОБНОВИТЬ ПЕРЕВОЗЧИКА
    updateCarrier({ commit }, payload ) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios
        .post(`${EPI}carrier/updateAll`, data)
        .then((res) => {
          commit("UPDATE_CARRIER", payload);
          return res
        })
    },
    fetchWareDetails(_, { wareId }) {
      return axios.get(`${EPI}wareDetails/getByWareId?wareId=${wareId}`);
    },
    createWareDetail(_, { wareId, index }) {
      return axios.post(
        `${EPI}wareDetails/create?wareId=${wareId}&sortIndex=${index}`
      );
    },
    deleteWareDetail(_, { wareId }) {
      return axios.post(`${EPI}wareDetails/deleteById?id=${wareId}`);
    },
    updateWareDetail(_, payload) {
      const data = Array.isArray(payload) ? payload : [payload];
      return axios.post(`${EPI}wareDetails/updateAll`, data);
    },
    copyWareDetail(_, payload) {
      return axios.post(
        `${EPI}wareDetails/copyWareDetails?id=${payload.id}&sortIndex=${payload.index}`
      );
    },
    getAvailableExcise(_, payload) {
      if (!payload.tn_ved) Promise.reject(new Error("Отсутствует код ТНВЭД"));
      const promise = new Promise((resolve, reject) => {
        axios
          .get(
            `${TNVED}api/read/nsiTransitExcise/getByTnVed?tnVed=${payload.tn_ved}`
          )
          .then(({ data }) => {
            if (!data.length) {
              if (!payload.date) {
                let error = new Error(
                  "Отсутствует дата для загрузки акциза из таможни"
                );
                error.color = "orange";
                throw error;
              }
              axios
                .get(
                  `${TNVED}api/read/getExcisesByTnVed?tnVed=${payload.tn_ved}&declarationDate=${payload.date}`
                )
                .then(({ data }) => {
                  resolve({ data, type: 2 });
                })
                .catch((err) => reject(err));
            } else {
              resolve({ data, type: 1 });
            }
          })
          .catch((e) => reject(e));
      });
      return promise;
    },
    uploadExcel(
      { dispatch, state },
      { formData, id, wareShipmentId, overwrite, joinArticleDescription }
    ) {
      return axios
        .post(
          `${EPI}declaration/uploadXlsx?declarationId=${id}&wareShipmentId=${wareShipmentId}` +
          `&joinArticleDescription=${joinArticleDescription}` +
          `&overwrite=${overwrite}`,
          formData
        )
        .then((res) => {
          dispatch('getDocumentById', state.selected.id)
          // commit("REPLACE_BLOCK_IN_DECLARATION", res.data);
          return res;
        });
    },
    copyDocument({ rootState }, { id, copyDocuments, wareIds, copyAllWares, copyGuarantees }) {
      const uid = rootState?.auth?.user?.user?.id;
      if (!uid) return Promise.reject(new Error("Отсутствует пользователь"));
      if (!id) return Promise.reject(new Error("Отсутствует ID документа"));
      let url =
        `${EPI}declaration/copyDeclaration?declarationToCopyId=${id}` +
        `&copyDocuments=${copyDocuments}` +
        `&userId=${uid}` +
        `&copyAllWares=${copyAllWares}`+
        `&copyGuarantees=${copyGuarantees}`
      ;
      return axios.post(url, wareIds);
    },
    downloadTransportDeclaration(_, {
      id, items, common
    }){
      return axios.post(`${EPI}declaration/tdtsPdf?id=${id}&commonTdts=${common}`, items, {
        responseType:'blob'
      })
    },
    createTransitDeclaration({ rootState }, declarationId){
      const userId = rootState?.auth?.user?.user?.id;
      return axios.post(`${EPI}declaration/createTd?declarationId=${declarationId}&userId=${userId}`)
    },
    getWarrantyApplication(_, {declarationId, payload}){
      return axios.post(`${EPI}declaration/zsoPdf?id=${declarationId}`, payload, {
        responseType:'blob'
      })
    }
  },
  getters: {
    getSelected: (s) => s.selected,
    getSelectedWares: (s) => s.selectedWares,
    getRequests: (s) => s.requests,
    getNsiAeos: (s) => s.aeos,
    getNsiTransportMarks: (s) => s.nsiTransportMarks,
    getShipmentIndex: (s) => s.shipmentIndex,
    getControl: (s) => s.control,
    getVisibility: (s) => !!s.selected?.registration_details?.reg_num_a,
    getEditableWare: (s) => s.editableWare,
    getIsOldStructure: (state, getters, rootState, rootGetters) => {
      const updateDate = rootGetters['catalogs/getXsdVersion']
        .find(item => item.declarationType.toUpperCase() === "EPI")?.dateUpdate;
      return updateDate ? state.selected.about_declaration?.declaration_date < updateDate : false;
    }
  },
};
